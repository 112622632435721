import React, {useCallback, useEffect, useRef, useState} from 'react'
import {utils as XLSXUtils, writeFile} from 'xlsx'
import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import {Loader, ErrorModal, MultiDateTimePicker} from '../../CommonFunctions/CommonFunction'
import '../../../css/systemActivity.css'
import {GetCompanySummaryOverviewReport} from '../../services/common.services'
import dayjs from 'dayjs'

const CompanyOverview: React.FC = () => {
  //newonboarding registered company
  const [newOnboardingRegisteredCompany, setnewOnboardingRegisteredCompany] = useState<any[]>([])
  const [newOnboardedRegisteredCompanyPinnedRow, setNewOnboardedRegisteredCompanyPinnedRow] =
    useState<any[]>()
  const [newOnboardingRegisteredCompanyDefs, setNewOnboardingRegisteredCompanyDefs] = useState<
    any[]
  >([])

  //new onboarded compnay
  const [newOnboardedCompany, setnewOnboardedCompany] = useState<any[]>([])
  const [newOnboardedCompanyPinnedRow, setNewOnboardedCompanyPinnedRow] = useState<any[]>()
  const [newOnboardingCompanyDefs, setNewOnboardingCompanyDefs] = useState<any[]>([])

  //active company
  const [activeCompany, setActiveCompany] = useState<any[]>([])
  const [activeCompanyPinnedRow, setActiveCompanyPinnedRow] = useState<any[]>()
  const [activeCompanyDefs, setActiveCompanyDefs] = useState<any[]>([])

  //inactive company
  const [inActiveCompany, setInActiveCompany] = useState<any[]>([])
  const [inActiveCompanyPinnedRow, setInActiveCompanyPinnedRow] = useState<any[]>()
  const [inActiveCompanyDefs, setInActiveCompanyDefs] = useState<any[]>([])

  const [screenloader, setScreenLoader] = useState<any>(false)
  const gridRef: any = useRef()
  // const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any[]>()
  const [summary, setSummary] = useState<any>({})
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(6, 'day').toDate())
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())

  useEffect(() => {
    getCompanyData()
  }, [startDate])

  const getCompanyData = async () => {
    try {
      setScreenLoader(true)

      const body = {
        fromDate: dayjs(startDate).format(),
        toDate: dayjs(endDate).format(),
      }

      // Fetch company summary data based on the provided date range
      const companyData = await GetCompanySummaryOverviewReport(body)

      // Execute all formatting functions concurrently to optimize performance
      await Promise.all([
        formatSummaryTableData(companyData.data.summaryReportEmailResponse),
        formatNewOnboardedRegisteredComapanyDetails(
          companyData.data.newOnboardedCompaniesRegisteredDetails
        ),
        formatNewOnboardedCompanyDetails(companyData.data.newOnboardedCompaniesDetails),
        formatActiveCompanyDetails(companyData.data.activeCompaniesDetails),
        formatInActiveCompanyDetails(companyData.data.inActiveCompaniesDetails),
      ])
    } catch (error) {
      ErrorModal()
    } finally {
      setScreenLoader(false)
    }
  }

  const formatSummaryTableData = (summaryData: any) => {
    try {
      setScreenLoader(true)
      setSummary(summaryData)
      setScreenLoader(false)
    } catch (error) {
      ErrorModal()
    }
  }

  const formatNewOnboardedRegisteredComapanyDetails = async (newOnboardingCompanyData: any) => {
    try {
      setScreenLoader(true) // Activate screen loader while processing the data

      // Format the new onboarding company data
      const formattedData = newOnboardingCompanyData.map((company: any) => ({
        companyName: company.companyName !== '-' ? company.companyName : '-', // Retain company name if valid, otherwise use '-'
        email: company.email || '-', // Use email if available, otherwise use '-'
        contactNo: company.contactNo !== '-' ? company.contactNo : '-', // Retain contact number if valid, otherwise use '-'
        fullName: company.fullName !== '-' ? company.fullName : '-', // Retain full name if valid, otherwise use '-'
        registrationDate: dayjs(company.registrationDate).format('DD-MM-YYYY'), // Format the registration date
      }))

      // Calculate the total number of companies with valid names
      const totalCompanies = formattedData.reduce(
        (acc: any, company: any) => acc + (company.companyName !== '-' ? 1 : 0),
        0
      )

      // Update state with the formatted data
      setnewOnboardingRegisteredCompany(formattedData)

      // Set pinned row to display the total number of companies
      setNewOnboardedRegisteredCompanyPinnedRow([
        {
          companyName: `Total Companies: ${totalCompanies}`,
        },
      ])

      // Define column configurations for the data grid
      const columnDefs = [
        {
          headerName: 'Company Name',
          field: 'companyName',
          flex: 1,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          flex: 2,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Contact No',
          field: 'contactNo',
          flex: 1,
          sortable: false,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Full Name',
          field: 'fullName',
          flex: 1,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Registration Date',
          field: 'registrationDate',
          flex: 1,
          filter: true,
          floatingFilter: true,
          type: 'Date',
        },
      ]

      setNewOnboardingRegisteredCompanyDefs(columnDefs)

      setScreenLoader(false)
    } catch (error) {
      ErrorModal()
    }
  }

  const formatNewOnboardedCompanyDetails = async (newOnboardedCompanyData: any) => {
    try {
      setScreenLoader(true)

      // Format the data to ensure consistent structure and data types
      const formattedData = newOnboardedCompanyData.map((company: any) => ({
        companyName: company.organizationName !== '-' ? company.organizationName : '-', // Use organization name or '-' if not available
        email: company.organizationEmail || '-', // Use organization email or '-' if not available
        contactNo: company.contactNumber !== null ? company.contactNumber : '-', // Use contact number or '-' if not available
        registrationDate: company.registrationDate, // Use the provided registration date
        totalUsers: Number(company.totalUsers) || 0, // Convert total users to number, default to 0 if invalid
        employeesActivated: Number(company.employeesActivated) || 0, // Convert activated employees to number, default to 0 if invalid
        totalWorkingUsers: Number(company.totalWorkingUsers) || 0, // Convert working users to number, default to 0 if invalid
        totalActiveUsers: Number(company.totalActiveUsers) || 0, // Convert active users to number, default to 0 if invalid
        totalActiveAdmin: Number(company.totalActiveAdmin) || 0, // Convert active admins to number, default to 0 if invalid
      }))

      // Calculate totals for various metrics across all companies
      const totalCompanies = formattedData.filter(
        (company: any) => company.companyName !== '-'
      ).length

      const totalUsers = formattedData.reduce(
        (acc: number, company: any) => acc + company.totalUsers,
        0
      )

      const totalEmployeesActivated = formattedData.reduce(
        (acc: number, company: any) => acc + company.employeesActivated,
        0
      )

      const totalWorkingUsers = formattedData.reduce(
        (acc: number, company: any) => acc + company.totalWorkingUsers,
        0
      )

      const totalActiveUsers = formattedData.reduce(
        (acc: number, company: any) => acc + company.totalActiveUsers,
        0
      )

      const totalActiveAdmin = formattedData.reduce(
        (acc: number, company: any) => acc + company.totalActiveAdmin,
        0
      )

      setnewOnboardedCompany(formattedData)

      // Prepare the pinned row data to display totals in the table
      const totalData = [
        {
          companyName: `Total Companies: ${totalCompanies}`,
          email: '',
          contactNo: '',
          registrationDate: '',
          totalUsers: totalUsers,
          employeesActivated: totalEmployeesActivated,
          totalWorkingUsers: totalWorkingUsers,
          totalActiveUsers: totalActiveUsers,
          totalActiveAdmin: totalActiveAdmin,
        },
      ]
      setNewOnboardedCompanyPinnedRow(totalData)

      // Define column configurations for the data grid
      const columnDefs = [
        {
          headerName: 'Company Name',
          field: 'companyName',
          width: 400,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 400,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Contact No',
          field: 'contactNo',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Registration Date',
          field: 'registrationDate',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Date',
        },
        {
          headerName: 'Employees Added',
          field: 'totalUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employees Activated',
          field: 'employeesActivated',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employee Monitored',
          field: 'totalWorkingUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employee Engagement',
          field: 'totalActiveUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Admin Engagement',
          field: 'totalActiveAdmin',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
      ]

      setNewOnboardingCompanyDefs(columnDefs)

      setScreenLoader(false)
    } catch (error) {
      console.error('Error formatting company details:', error)
      ErrorModal()
    }
  }

  const formatActiveCompanyDetails = async (activeCompanyData: any) => {
    try {
      setScreenLoader(true)
      // Format the data to ensure consistent structure and data types
      const formattedData = activeCompanyData.map((company: any) => ({
        companyName: company.organizationName !== '-' ? company.organizationName : '-', // Use organization name or '-' if not available
        email: company.organizationEmail || '-', // Use organization email or '-' if not available
        contactNo: company.contactNumber !== null ? company.contactNumber : '-', // Use contact number or '-' if not available
        registrationDate: company.registrationDate, // Use the provided registration date
        accountActivationDate: company.accountActivationDate, // Use the provided account activation date
        totalUsers: Number(company.totalUsers) || 0, // Convert total users to number, default to 0 if invalid
        employeesActivated: Number(company.employeesActivated) || 0, // Convert activated employees to number, default to 0 if invalid
        totalWorkingUsers: Number(company.totalWorkingUsers) || 0, // Convert working users to number, default to 0 if invalid
        totalActiveUsers: Number(company.totalActiveUsers) || 0, // Convert active users to number, default to 0 if invalid
        totalActiveAdmin: Number(company.totalActiveAdmin) || 0, // Convert active admins to number, default to 0 if invalid
        adminLatestAccessDate: company.adminLatestAccessDate || '-', // Use the provided admin latest access date
      }))

      // Compute totals for various metrics across all active companies
      const totals = formattedData.reduce(
        (acc: any, company: any) => ({
          totalCompanies: acc.totalCompanies + (company.companyName !== '-' ? 1 : 0), // Count only valid companies
          totalEmployeesActivated: acc.totalEmployeesActivated + company.employeesActivated, // Sum of activated employees
          totalActiveAdmin: acc.totalActiveAdmin + company.totalActiveAdmin, // Sum of active admins
          totalActiveUsers: acc.totalActiveUsers + company.totalActiveUsers, // Sum of active users
          totalUsers: acc.totalUsers + company.totalUsers, // Sum of total users
          totalWorkingUsers: acc.totalWorkingUsers + company.totalWorkingUsers, // Sum of working users
        }),
        {
          totalCompanies: 0,
          totalEmployeesActivated: 0,
          totalActiveAdmin: 0,
          totalActiveUsers: 0,
          totalUsers: 0,
          totalWorkingUsers: 0,
        }
      )

      setActiveCompany(formattedData)

      // Prepare the pinned row data to display totals in the table
      const totalData = [
        {
          companyName: `Total Companies: ${totals.totalCompanies}`,
          email: '',
          contactNo: '',
          registrationDate: '',
          accountActivationDate: '',
          totalUsers: totals.totalUsers,
          employeesActivated: totals.totalEmployeesActivated,
          totalWorkingUsers: totals.totalWorkingUsers,
          totalActiveUsers: totals.totalActiveUsers,
          totalActiveAdmin: totals.totalActiveAdmin,
        },
      ]
      setActiveCompanyPinnedRow(totalData)

      // Define column configurations for the data grid
      const columnDefs = [
        {
          headerName: 'Company Name',
          field: 'companyName',
          width: 400,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 400,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Contact No',
          field: 'contactNo',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Registration Date',
          field: 'registrationDate',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Date',
        },
        {
          headerName: 'Account Activation Date',
          field: 'accountActivationDate',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Date',
        },
        {
          headerName: 'Employees Added',
          field: 'totalUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employees Activated',
          field: 'employeesActivated',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employee Monitored',
          field: 'totalWorkingUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employee Engagement',
          field: 'totalActiveUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Admin Engagement',
          field: 'totalActiveAdmin',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Admin Latest Access Date',
          field: 'adminLatestAccessDate',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'DateTime',
        },
      ]

      setActiveCompanyDefs(columnDefs)

      setScreenLoader(false)
    } catch (error) {
      console.error('Error formatting active company details:', error)
      ErrorModal()
    }
  }

  const formatInActiveCompanyDetails = async (inactiveCompanyData: any) => {
    try {
      setScreenLoader(true)

      // Format the data to ensure consistency and convert numeric values
      const formattedData = inactiveCompanyData.map((company: any) => ({
        companyName: company.organizationName !== '-' ? company.organizationName : '-', // Use organization name or default to '-'
        email: company.organizationEmail || '-', // Use organization email or default to '-'
        contactNo: company.contactNumber || '-', // Use contact number or default to '-'
        registrationDate: company.registrationDate, // Use registration date
        accountActivationDate: company.accountActivationDate, // Use account activation date
        totalUsers: Number(company.totalUsers) || 0, // Convert total users to number, default to 0 if invalid
        employeesActivated: Number(company.employeesActivated) || 0, // Convert activated employees to number, default to 0 if invalid
        totalWorkingUsers: Number(company.totalWorkingUsers) || 0, // Convert working users to number, default to 0 if invalid
        totalActiveUsers: Number(company.totalActiveUsers) || 0, // Convert active users to number, default to 0 if invalid
        totalActiveAdmin: Number(company.totalActiveAdmin) || 0, // Convert active admins to number, default to 0 if invalid
      }))

      // Compute totals across all inactive companies
      const totals = formattedData.reduce(
        (acc: any, company: any) => ({
          totalCompanies: acc.totalCompanies + (company.companyName !== '-' ? 1 : 0), // Count valid companies
          totalEmployeesActivated: acc.totalEmployeesActivated + company.employeesActivated, // Sum of activated employees
          totalActiveAdmin: acc.totalActiveAdmin + company.totalActiveAdmin, // Sum of active admins
          totalActiveUsers: acc.totalActiveUsers + company.totalActiveUsers, // Sum of active users
          totalUsers: acc.totalUsers + company.totalUsers, // Sum of total users
          totalWorkingUsers: acc.totalWorkingUsers + company.totalWorkingUsers, // Sum of working users
        }),
        {
          totalCompanies: 0,
          totalEmployeesActivated: 0,
          totalActiveAdmin: 0,
          totalActiveUsers: 0,
          totalUsers: 0,
          totalWorkingUsers: 0,
        }
      )

      // Set the formatted data and pinned row data
      setInActiveCompany(formattedData)
      setInActiveCompanyPinnedRow([
        {
          companyName: `Total Companies: ${totals.totalCompanies}`,
          email: '',
          contactNo: '',
          registrationDate: '',
          accountActivationDate: '',
          totalUsers: totals.totalUsers,
          employeesActivated: totals.totalEmployeesActivated,
          totalWorkingUsers: totals.totalWorkingUsers,
          totalActiveUsers: totals.totalActiveUsers,
          totalActiveAdmin: totals.totalActiveAdmin,
        },
      ])

      // Define column definitions for the data grid
      const columnDefs = [
        {
          headerName: 'Company Name',
          field: 'companyName',
          width: 400,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 400,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: 'Contact No',
          field: 'contactNo',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Registration Date',
          field: 'registrationDate',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Date',
        },
        {
          headerName: 'Account Activation Date',
          field: 'accountActivationDate',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Date',
        },
        {
          headerName: 'Employees Added',
          field: 'totalUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employees Activated',
          field: 'employeesActivated',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employee Monitored',
          field: 'totalWorkingUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Employee Engagement',
          field: 'totalActiveUsers',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
        {
          headerName: 'Admin Engagement',
          field: 'totalActiveAdmin',
          width: 200,
          filter: true,
          floatingFilter: true,
          type: 'Number',
        },
      ]

      setInActiveCompanyDefs(columnDefs)
      setScreenLoader(false)
    } catch (error) {
      console.error('Error formatting inactive company details:', error)
      ErrorModal()
    }
  }

  const createExcel = async (e: any) => {
    e.preventDefault()

    // Prepare summary data
    const summaryData = [
      {
        'Active Dashboard Admin': summary.totalActiveDashboardAdmins,
        'New Companies Registered': summary.totalNewOrganizationsRegistered,
        'New Companies Onboarded': summary.totalNewOrganizationsOnboarded,
        'Total Companies': summary.totalOrganizations,
        'Active Companies': summary.totalActiveOrganizations,
        'Active Dashboard Users': summary.totalActiveDashboardUsers,
        'Monitored Users': summary.totalMonitoredUsers,
      },
    ]

    // Prepare onboarding data
    const registeredCompaiesData = newOnboardingRegisteredCompany.map((company: any) => ({
      'Company Name': company.companyName,
      Email: company.email,
      'Contact No': company.contactNo,
      'Full Name': company.fullName,
      'Registration Date': company.registrationDate,
    }))

    // Prepare onboarded company data
    const onboardedCompanyData = newOnboardedCompany.map((company: any) => ({
      'Company Name': company.companyName,
      Email: company.email,
      'Contact No': company.contactNo,
      'Registration Date': company.registrationDate,
      'Employees Added': company.totalUsers,
      'Employees Activated': company.employeesActivated,
      'Employee Monitored': company.totalWorkingUsers,
      'Employee Engagement': company.totalActiveUsers,
      'Admin Engagement': company.totalActiveAdmin,
    }))

    // Prepare active company data, including the adminLatestAccessDate column
    const activeCompanyData = activeCompany.map((company: any) => ({
      'Company Name': company.companyName,
      Email: company.email,
      'Contact No': company.contactNo,
      'Registration Date': company.registrationDate,
      'Account Activation Date': company.accountActivationDate,
      'Employees Added': company.totalUsers,
      'Employees Activated': company.employeesActivated,
      'Employee Monitored': company.totalWorkingUsers,
      'Employee Engagement': company.totalActiveUsers,
      'Admin Engagement': company.totalActiveAdmin,
      'Admin Latest Access Date': company.adminLatestAccessDate,
    }))

    // Prepare inactive company data
    const inActiveCompanyData = inActiveCompany.map((company: any) => ({
      'Company Name': company.companyName,
      Email: company.email,
      'Contact No': company.contactNo,
      'Registration Date': company.registrationDate,
      'Account Activation Date': company.accountActivationDate,
      'Employees Added': company.totalUsers,
      'Employees Activated': company.employeesActivated,
      'Employee Monitored': company.totalWorkingUsers,
      'Employee Engagement': company.totalActiveUsers,
      'Admin Engagement': company.totalActiveAdmin,
    }))

    const workbook = XLSXUtils.book_new()

    // Add summary data sheet
    const summaryWorksheet = XLSXUtils.json_to_sheet(summaryData, {
      header: [
        'New Companies Registered',
        'New Companies Onboarded',
        'Total Companies',
        'Active Companies',
        'Active Dashboard Admin',
        'Active Dashboard Users',
        'Monitored Users',
      ],
    })
    XLSXUtils.book_append_sheet(workbook, summaryWorksheet, 'Summary')

    // Add onboarding data sheet
    const onboardingWorksheet = XLSXUtils.json_to_sheet(registeredCompaiesData, {
      header: ['Company Name', 'Email', 'Contact No', 'Full Name', 'Registration Date'],
    })
    XLSXUtils.book_append_sheet(workbook, onboardingWorksheet, 'New Registered Companies')

    // Add onboarded company data sheet
    const onboardedCompanyWorksheet = XLSXUtils.json_to_sheet(onboardedCompanyData, {
      header: [
        'Company Name',
        'Email',
        'Contact No',
        'Registration Date',
        'Employees Added',
        'Employees Activated',
        'Employee Monitored',
        'Employee Engagement',
        'Admin Engagement',
      ],
    })
    XLSXUtils.book_append_sheet(workbook, onboardedCompanyWorksheet, 'New Onboarded Companies')

    // Add active company data sheet, including the adminLatestAccessDate column
    const activeCompanyWorksheet = XLSXUtils.json_to_sheet(activeCompanyData, {
      header: [
        'Company Name',
        'Email',
        'Contact No',
        'Registration Date',
        'Account Activation Date',
        'Employees Added',
        'Employees Activated',
        'Employee Monitored',
        'Employee Engagement',
        'Admin Engagement',
        'Admin Latest Access Date',
      ],
    })
    XLSXUtils.book_append_sheet(workbook, activeCompanyWorksheet, 'Active Companies')

    // Add inactive company data sheet
    const inActiveCompanyWorksheet = XLSXUtils.json_to_sheet(inActiveCompanyData, {
      header: [
        'Company Name',
        'Email',
        'Contact No',
        'Registration Date',
        'Account Activation Date',
        'Employees Added',
        'Employees Activated',
        'Employee Monitored',
        'Employee Engagement',
        'Admin Engagement',
      ],
    })
    XLSXUtils.book_append_sheet(workbook, inActiveCompanyWorksheet, 'Inactive Companies')

    // Write the workbook to a file
    writeFile(workbook, 'Companies Data.xlsx')
  }

  const paginationPageSizeSelector = [10, 25, 50, 100]

  const getRowStyle: any = (params: any) => {
    if (params.node.rowPinned) {
      return {fontWeight: 'bold', background: '#009EF7'}
    }
    if (params.node.rowIndex % 2 === 0) {
      return {background: 'lightBlue'}
    }
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(dates)
    setStartDate(start)
  }

  return (
    <>
      <div className={`card `}>
        <div className='card-header border-0 pt-5'>
          <div className='d-flex justify-content-between align-items-center py-3  w-100'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Companies</span>
            </h3>

            <div className='d-flex'>
              <div className='d-flex flex-column' style={{marginRight: '1rem'}}>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height',
                  '',
                  false
                )}
              </div>

              <div className='d-flex '>
                <div style={{alignItems: 'center', display: 'flex'}}>
                  <button
                    className='btn btn-primary'
                    style={{height: '3rem'}}
                    onClick={(e) => createExcel(e)}
                  >
                    <i className='fa fa-download'></i>Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {screenloader ? (
        <div className='card mt-6'>
          <div className='mt-5'>{Loader('0px')}</div>
        </div>
      ) : (
        <>
          <div className='card mt-5 w-50'>
            <div className='card-body py-3' style={{padding: '30px'}}>
              <div className='table-responsive'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Summary Report</span>
                </h3>
                <table className='table align-middle gs-0 gy-4 mt-2' style={{border: '1px solid '}}>
                  <thead>
                    <tr className='fw-bolder' style={{background: '#728FCE', fontSize: '15px'}}>
                      <th className='min-w-100px text-center'>Metric</th>
                      <th className='min-w-150px text-center'>Last Week</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{border: '1px solid black '}}>
                      <td className=' '>
                        <span className='fs-6' style={{paddingLeft: '5px'}}>
                          New Companies Registered
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 text-danger'>
                            {summary?.totalNewOrganizationsRegistered}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{border: '1px solid black '}}>
                      <td className=' '>
                        <span className='fs-6' style={{paddingLeft: '5px'}}>
                          New Companies Onboarded
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 text-danger'>
                            {summary.totalNewOrganizationsOnboarded}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{border: '1px solid black '}}>
                      <td className=' '>
                        <span className='fs-6 py-2' style={{paddingLeft: '5px'}}>
                          Total Companies
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 text-danger'>{summary.totalOrganizations}</span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{border: '1px solid black '}}>
                      <td className=''>
                        <span className='fs-6 fw-bold' style={{paddingLeft: '5px'}}>
                          Active Companies
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 fw-6 text-danger'>
                            {summary.totalActiveOrganizations}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{border: '1px solid black '}}>
                      <td className='   '>
                        <span className='fs-6 fw-6' style={{paddingLeft: '5px'}}>
                          Active Dashboard Admin
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 fw-6 text-danger'>
                            {summary.totalActiveDashboardAdmins}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{border: '1px solid black '}}>
                      <td className='  '>
                        <span className='fs-6 fw-6' style={{paddingLeft: '5px'}}>
                          Active Dashboard Users
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 fw-6 text-danger'>
                            {summary.totalActiveDashboardUsers}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{border: '1px solid black '}}>
                      <td className='  '>
                        <span className='fw-6 fs-6' style={{paddingLeft: '5px'}}>
                          Monitored Users
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='fs-6 fw-6 text-danger'>
                            {summary.totalMonitoredUsers}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='card ag-theme-quartz table-responsive h-500px mt-5 w-100'>
            <span className='fs-2 fw-bolder px-5 py-4'>New Registered Companies</span>
            <div className='card-body px-5 py-6'>
              <AgGridReact
                ref={gridRef}
                rowHeight={34.4}
                getRowStyle={getRowStyle}
                rowData={newOnboardingRegisteredCompany}
                columnDefs={newOnboardingRegisteredCompanyDefs}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                pinnedBottomRowData={newOnboardedRegisteredCompanyPinnedRow}
                sideBar={false}
              />
            </div>
          </div>
          <div className='card ag-theme-quartz table-responsive h-600px mt-5 w-100'>
            <span className='fs-2 fw-bolder px-5 py-4'>New Onboarded Companies</span>
            <div className='card-body px-5 py-6'>
              <AgGridReact
                ref={gridRef}
                rowHeight={34.4}
                getRowStyle={getRowStyle}
                rowData={newOnboardedCompany}
                columnDefs={newOnboardingCompanyDefs}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                pinnedBottomRowData={newOnboardedCompanyPinnedRow}
                sideBar={false}
              />
            </div>
          </div>
          <div className='card ag-theme-quartz table-responsive h-600px mt-5 w-100'>
            <span className='fs-2 fw-bolder px-5 py-4'>Active Companies</span>
            <div className='card-body px-5 py-6'>
              <AgGridReact
                ref={gridRef}
                rowHeight={34.4}
                getRowStyle={getRowStyle}
                rowData={activeCompany}
                columnDefs={activeCompanyDefs}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                pinnedBottomRowData={activeCompanyPinnedRow}
                sideBar={false}
              />
            </div>
          </div>
          <div className='card ag-theme-quartz table-responsive h-600px mt-5 w-100'>
            <span className='fs-2 fw-bolder px-5 py-4'>In Active Companies</span>
            <div className='card-body px-5 py-6'>
              <AgGridReact
                ref={gridRef}
                rowHeight={34.4}
                getRowStyle={getRowStyle}
                rowData={inActiveCompany}
                columnDefs={inActiveCompanyDefs}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                pinnedBottomRowData={inActiveCompanyPinnedRow}
                sideBar={false}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {CompanyOverview}
